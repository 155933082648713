<template>
<div>加载中</div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'Sso',
  data: function () {
    return {
      loginInfo: {}
    }
  },
  created () {
    const secret = this.$route.query.secret
    requestForm('/api/auth/oaLogin', 'post', { flag: secret }).then((response) => {
      if (response.code === '200') {
        localStorage.setItem('token', response.data)
      }
      location.href = '/index'
    })
  }
}
</script>

<style scoped>

</style>
